import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import PauseScreenBubble from "./PauseScreenBubble";
import "./PauseModal.css";
import "./PauseScreenBubble.css";

function PauseModal(props) {
  const {
    survey,
    toLastPage,
    replayHandler,
    handlePauseAudio,
    setIsTimerPaused,
    showPause,
    setShowPause,
  } = props;
  const practiceQuestion = survey?.currentPage?.questions[0].practice;

  const [modalKey, setModalKey] = useState(0);
  const [alpacaTimer, setAlpacaTimer] = useState();

  function handleResume() {
    console.log("Resume button is clicked");

    replayHandler();

    setIsTimerPaused(false);
  }

  function handleClose() {
    setShowPause(false);
  }

  function handleShow() {
    setModalKey((prevKey) => prevKey + 1);
    setShowPause(true);
    setIsTimerPaused(true);
    handlePauseAudio();
    console.log("Paused");
  }

  return (
    <>
      <Button id="pause-button" className="modal-button" onClick={handleShow}>
        <img
          src="assets/imgs/pause-button.svg"
          className="logout-button"
          alt="Pause Button"
        />
      </Button>
      <Modal
        id="modal"
        dialogClassName="my-modal"
        show={showPause}
        onHide={handleClose}
        key={modalKey}
      >
        <Modal.Body style={{ backgroundColor: "#5eb4cd" }}>
          <PauseScreenBubble
            survey={survey}
            toLastPage={toLastPage}
            handleClose={handleClose}
            handleResume={handleResume}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PauseModal;
