import React, { useState, useEffect, lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import "./app.scss";

import BGMusicPlayer from "./components/BGMusicPlayer";
import ErrorBoundary from "./components/ErrorBoundary";
import OrientationPrompt from "./components/OrientationPrompt";
import UpdateModal from "./components/UpdateModal";

// Define lazy-loaded components
const Login = lazy(() => import("./pages/Login"));
const Intro = lazy(() => import("./pages/Intro"));

const Progress = lazy(() => import("./pages/Progress"));
const Assessment = lazy(() => import("./pages/Assessment"));
const NoAssessment = lazy(() => import("./pages/NoAssessment"));
const Complete = lazy(() => import("./pages/Complete"));

const App = () => {
  const [studentid, setStudentid] = useState();
  const [studentName, setStudentName] = useState("");
  const [bgAudio, setBgAudio] = useState(null);
  const [bgAudioIsPlaying, setBgAudioIsPlaying] = useState(false);
  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const [newVersion, setNewVersion] = useState("");

  useEffect(() => {
    const handleServiceWorkerUpdate = (event) => {
      console.log("event", event);
      if (event.data && event.data.type === "NEW_VERSION_AVAILABLE") {
        setNewVersion(event.data.swVersion);
        setNewVersionAvailable(true);
        console.log("We got this far");
      } else {
        console.log("Uh oh we have a problem");
      }
    };

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.addEventListener(
        "message",
        handleServiceWorkerUpdate
      );
    }

    // Cleanup listener
    return () => {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.removeEventListener(
          "message",
          handleServiceWorkerUpdate
        );
      } else {
        console.log("where you go service worker???");
      }
    };
  }, []);

  const updateServiceWorker = () => {
    navigator.serviceWorker.getRegistration().then((registration) => {
      if (registration.waiting) {
        registration.waiting.postMessage({ type: "SKIP_WAITING" });
      }
    });
    window.location.reload();
  };

  useEffect(() => {
    const handleOrientationChange = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleOrientationChange);

    return () => {
      window.removeEventListener("resize", handleOrientationChange);
    };
  }, []);

  // to stop double tap to zoom
  useEffect(() => {
    const handleGestureEvent = (e) => {
      e.preventDefault();
      // special hack to prevent zoom-to-tabs gesture in safari
      document.body.style.zoom = 0.99;
    };

    // Add event listeners for gesture events
    document.addEventListener("gesturestart", handleGestureEvent);
    document.addEventListener("gesturechange", handleGestureEvent);
    document.addEventListener("gestureend", handleGestureEvent);

    // Remove event listeners when component unmounts
    return () => {
      document.removeEventListener("gesturestart", handleGestureEvent);
      document.removeEventListener("gesturechange", handleGestureEvent);
      document.removeEventListener("gestureend", handleGestureEvent);
    };
  }, []);

  console.log(newVersionAvailable);

  return (
    <div>
      <BGMusicPlayer
        bgAudioFile={"/assets/audio/Little_Samba-full-quiet.mp3"}
        bgIsPlaying={bgAudioIsPlaying}
        setBgIsPlaying={setBgAudioIsPlaying}
      />
      <BGMusicPlayer
        bgAudioFile={"/assets/audio/touch-alpaca-prompt-Silence.mp3"}
        bgIsPlaying={bgAudioIsPlaying}
        setBgIsPlaying={setBgAudioIsPlaying}
      />

      <Suspense
        fallback={
          <div className="spinner-container">
            <div className="big-ball-spinner"></div>
          </div>
        }
      >
        <ErrorBoundary>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route
              path="intro"
              element={
                <Intro
                  studentName={studentName}
                  setStudentName={setStudentName}
                  setStudentid={setStudentid}
                  studentid={studentid}
                />
              }
            />

            <Route
              path="progress"
              element={
                <Progress
                  studentid={studentid}
                  setStudentid={setStudentid}
                  setStudentName={setStudentName}
                  setBgAudioIsPlaying={setBgAudioIsPlaying}
                />
              }
            />
            <Route
              path="assessment"
              element={
                <Assessment
                  setBgAudioIsPlaying={setBgAudioIsPlaying}
                  isPortrait={isPortrait}
                />
              }
            />
            <Route path="complete" element={<Complete />} />
            <Route
              path="noassessment"
              element={
                <NoAssessment
                  studentName={studentName}
                  setBgAudioIsPlaying={setBgAudioIsPlaying}
                />
              }
            />
          </Routes>
        </ErrorBoundary>
      </Suspense>
      {isPortrait && <OrientationPrompt />}
      {newVersionAvailable && (
        <UpdateModal
          version={newVersion}
          onUpdate={updateServiceWorker}
          show={newVersionAvailable}
        />
      )}
    </div>
  );
};

export default App;
